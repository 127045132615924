import { addMinutes, addSeconds, isAfter, isBefore, subMinutes } from 'date-fns'

import { isHttps } from '@docpace/shared-react-utils/windowUtils'

const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token'
const ACCESS_TOKEN_STORAGE_KEY = 'access_token'
const ACCESS_TOKEN_EXPIRATION_KEY = 'access_token_expires_at'

export function makeServerHostname(envHost, port){
    return (
        envHost ??
        ((typeof window !== 'undefined' && window.location.hostname === 'localhost') ? `localhost:${port}` : null) ??
        (typeof window !== 'undefined' ? window.location.hostname : '')
    )
}

export function isTokenValidOrUndefined(){
    const { accessToken, refreshToken, accessTokenExpiresAt } = initializeAuthState()
    if (!refreshToken ||
        (accessToken 
            && refreshToken 
            && accessTokenExpiresAt 
            && isBefore(new Date(), subMinutes(new Date(accessTokenExpiresAt), 5))
        )    
    ) return true
    return false
}

export function initializeAuthState() {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
    const accessTokenExpiresAt = sessionStorage.getItem(
        ACCESS_TOKEN_EXPIRATION_KEY
    )
    return { accessToken, refreshToken, accessTokenExpiresAt };
}

export function makeGraphqlApiHttpUrl(serverHostname){
    return `http${isHttps ? 's' : ''}://${serverHostname}/api/graphql`

}

export function makeGraphqlApiWsUrl(serverHostname){
    return `ws${isHttps ? 's' : ''}://${serverHostname}/api/graphql`

}