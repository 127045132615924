export const isHttps =
    typeof window !== 'undefined'
        ? window?.location?.protocol === 'https:'
        : false

export const hostnamePrefix = `http${isHttps ? 's' : ''}://`

export const getDevicePixelRatio = () =>
    typeof window !== 'undefined' ? window.devicePixelRatio : 1

export const getWindowAspectRatio = () =>
    typeof window !== 'undefined'
        ? window.innerWidth / window.innerHeight
        : 4 / 3

export function isPortraitMode(): boolean {
    return getWindowAspectRatio() < .8
} 