import { RetryLink } from 'apollo-link-retry'

export const apolloRetryLink = new RetryLink({
    attempts: (count, operation, error) => {
        return true
    },
    delay: (count, operation, error) => {
        return count * 1000 * Math.random()
    },
})
