import { split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

export function makeApolloLink(httpLink, wsLink, authLink) {
    return wsLink
        ? split(
              // the split prevents the client from crashing during SSR (ws is not available during SSR)
              ({ query }) => {
                  const definition = getMainDefinition(query)
                  return (
                      definition.kind === 'OperationDefinition' &&
                      definition.operation === 'subscription'
                  )
              },
              wsLink,
              authLink.concat(httpLink)
          )
        : authLink.concat(httpLink)
}
