export const sharedTypePolicies = {
    Actor: { keyFields: ['actorId'] },
    Admin: { keyFields: ['adminId'] },
    AdminFavoriteAppointmentType: {
        keyFields: ['adminFavoriteAppointmentTypeId'],
    },
    AdminFavoriteDepartment: { keyFields: ['adminFavoriteDepartmentId'] },
    AdminFavoritePractice: { keyFields: ['adminFavoritePracticeId'] },
    AdminFavoriteProvider: { keyFields: ['adminFavoriteProviderId'] },
    Appointment: { keyFields: ['appointmentId'] },
    AppointmentCalculationsWithNow: { keyFields: ['appointmentId'] },
    AppointmentCycleConfig: { keyFields: ['appointmentCycleConfigId'] },
    AppointmentCycleConfigComputed: { keyFields: ['practiceId','providerId','departmentId','appointmentTypeId'] },
    AppointmentCycleDefaultValue: {
        keyFields: ['appointmentCycleDefaultValueId'],
    },
    AppointmentDurationCompiled: { keyFields: ['appointmentId'] },
    AppointmentDurationLogRatiosCompiled: { keyFields: ['appointmentId'] },
    AppointmentEstimate: { keyFields: ['appointmentId'] },
    AppointmentType: { 
        keyFields: ['appointmentTypeId'],
        fields: {
            RecentAppointmentCountsPerHourAggregates: {
                merge: true,
            },
        },
    },
    AppointmentTypeProviderStat: { 
        keyFields: ['appointmentTypeId', 'provider_id'],
    },
    AppointmentText: { keyFields: ['appointmentTextId'] },
    AppointmentTokenSession: { keyFields: ['appointmentToken'] },
    AuthTableauResponse: { keyFields: ['jwt'] },
    ChatChannel: { keyFields: ['chatChannelId'] },
    ChatEvent: { keyFields: ['chatChannelId', 'eventTime', 'actorId'] },
    ChatNotification: { keyFields: ['chatChannelId', 'eventTime', 'actorId'] },
    Department: {
        keyFields: ['departmentId'],
        fields: {
            RecentAppointmentCountsPerHourAggregates: {
                merge: true,
            },
        },
    },
    DetailedAppointment: { keyFields: ['appointmentId'] },
    DetailedAppointmentText: { keyFields: ['appointmentTextId'] },
    GooglePlace: { keyFields: ['googlePlaceId'] },
    HubspotContact: { keyFields: ['hs_object_id'] },
    HubspotLineItem: { keyFields: ['hs_object_id'] },
    HubspotSubscription: { keyFields: ['hs_object_id'] },
    Manager: { keyFields: ['managerId'] },
    ManagerPasswordResetToken: { keyFields: ['managerPasswordResetTokenId'] },
    ManagerProviderPermission: { keyFields: ['managerProviderPermissionId'] },
    Patient: { keyFields: ['patientId'] },
    Practice: { keyFields: ['practiceId'] },
    PracticeSlug: { keyFields: ['practiceSlugId'] },
    PracticeSubscription: { keyFields: ['practiceSubscriptionId'] },
    Provider: { 
        keyFields: ['providerId'],
        fields: {
            RecentAppointmentCountsPerHourAggregates: {
                merge: true,
            },
        },
    },
    ProviderDepartmentAppointmentCount: { keyFields: ['practiceId','departmentId','providerId','dateScheduled','status'] },
    ProviderDepartmentOption: { keyFields: ['providerDepartmentOptionId'] },
    ProviderDepartmentOptionCompiled: { keyFields: ['providerId','departmentId','practiceId','appointmetTypeId'] },
    Template: { keyFields: ['templateId'] },
    Translation: { keyFields: ['translationId'] },
    TranslationContent: { keyFields: ['translationId', 'languageId'] },
    Query: {
        fields: {
            practice: {
                merge: true,
            },
        },
    },
}
