import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAtom } from 'jotai'

import { ctxIsChangingRoute } from '@docpace/shared-react-atoms'

// interface UseIsChangingRouteProps {}

interface UseIsChangingRouteOutput {
    isChangingRoute: boolean
}

export const useIsChangingRoute: () => UseIsChangingRouteOutput = () => {
    const router = useRouter()
    const [ isChangingRoute, setIsChangingRoute ] = useAtom(ctxIsChangingRoute)
    useEffect(() => {
        const handleStart = (url) => {
            setIsChangingRoute(true)
        }
        const handleStop = () => {
            setIsChangingRoute(false)
        }

        router.events.on('routeChangeStart', handleStart)
        router.events.on('routeChangeComplete', handleStop)
        router.events.on('routeChangeError', handleStop)

        return () => {
            router.events.off('routeChangeStart', handleStart)
            router.events.off('routeChangeComplete', handleStop)
            router.events.off('routeChangeError', handleStop)
        }
    }, [router])

    return {
        isChangingRoute,
    }
}

export default useIsChangingRoute
