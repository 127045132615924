import { TokenRefreshLink } from 'apollo-link-token-refresh'

import {
    refreshAccessTokenFromAPI,
    setSessionData,
    notifyNewSession,
} from '@docpace/shared-react-utils/sessionUtils'
import { isTokenValidOrUndefined } from '@docpace/shared-ts-utils'

interface MakeApolloRefreshTokenLinkInput {
    serverHostname: string
}

export const makeApolloRefreshTokenLink = ({ serverHostname } : MakeApolloRefreshTokenLinkInput) => {
    return new TokenRefreshLink({
        isTokenValidOrUndefined,
        fetchAccessToken: ()=>refreshAccessTokenFromAPI(serverHostname),
        handleFetch: (accessToken) => accessToken,
        handleResponse: (operation, accessTokenField) => async (response) => {
            await setSessionData(response)
            await notifyNewSession()
            return response
        },
        handleError: (err) => {
            if (
                !navigator.onLine ||
                (err instanceof TypeError &&
                    err.message === 'Network request failed')
            ) {
                console.log('Offline -> do nothing 🍵')
            } else {
                //  console.log("Online -> log out 👋")
                // your custom action here
                //  user.logout();
            }
        },
    })
}
