import { useState, useEffect } from 'react'
import { doesWindowHaveBroadcastChannel } from '@docpace/shared-react-utils/broadcastChannelUtils'

interface UseBroadcastChannelProps {
    channelName: string
    handleMessage?: (data: any) => void
}

export const useBroadcastChannel = ({
    channelName,
}: UseBroadcastChannelProps): [BroadcastChannel | null] => {
    const [broadcastChannel, setBroadcastChannel] = useState(
        doesWindowHaveBroadcastChannel()
            ? new BroadcastChannel(channelName)
            : null
    )

    useEffect(() => {
        return () => {
            try {
                // !!broadcastChannel && broadcastChannel?.close()
            } catch (e) {}
            setBroadcastChannel(null)
        }
    }, [])

    return [broadcastChannel]
}
