import { HttpLink } from '@apollo/client'

export function makeApolloHttpLink(httpUri: string) {
    return new HttpLink({
        fetch,
        uri: httpUri,
        fetchOptions: {
            // ...process.env.NODE_ENV !== 'production' ? { mode  : 'no-cors' } : {},
        },
    })
}
