import { doesWindowHaveBroadcastChannel } from './broadcastChannelUtils'

export interface SessionResponse {
    access_token: string | null
    refresh_token: string | null
    server_access_token?: string | null
    session_expires_at: string | null
    access_token_expires_at: string | null
    refresh_token_expires_at: string | null
}

export const SESSION_SHARING_BROADCAST_CHANNEL = 'SESSION_SHARING'

export enum SessionSharingMessageType {
    AUTH_TOKEN_REQUEST = 'AUTH_TOKEN_REQUEST',
    AUTH_TOKEN_RESPONSE = 'AUTH_TOKEN_RESPONSE',
    CLEAR_SESSION_DATA = 'CLEAR_SESSION_DATA',
}

export const setSessionData = async (sessionData: any) => {
    if (sessionData?.access_token && sessionData?.refresh_token) {
        if (sessionData?.access_token) {
            await sessionStorage.setItem(
                'access_token',
                sessionData?.access_token
            )
        } else {
            await sessionStorage.removeItem('access_token')
        }

        if (sessionData?.access_token_expires_at) {
            await sessionStorage.setItem(
                'access_token_expires_at',
                sessionData?.access_token_expires_at
            )
        } else {
            await sessionStorage.removeItem('access_token_expires_at')
        }

        if (sessionData?.refresh_token) {
            await sessionStorage.setItem(
                'refresh_token',
                sessionData?.refresh_token
            )
        } else {
            await sessionStorage.removeItem('refresh_token')
        }

        if (sessionData?.refresh_token_expires_at) {
            await sessionStorage.setItem(
                'refresh_token_expires_at',
                sessionData?.refresh_token_expires_at
            )
        } else {
            await sessionStorage.removeItem('refresh_token_expires_at')
        }

        if (sessionData?.session_expires_at) {
            await sessionStorage.setItem(
                'session_expires_at',
                sessionData?.session_expires_at
            )
        } else {
            await sessionStorage.removeItem('session_expires_at')
        }
    }
}

export const refreshAccessTokenFromAPI = async (serverHostname: string) => {
    const refreshToken = sessionStorage.getItem('refresh_token')
    const accessToken = sessionStorage.getItem('access_token')
    const res = await fetch(`//${serverHostname}/api/auth/refresh`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ refreshToken }),
    })
    const sessionData = await res.json()

    if (res.status === 500) {
        throw new Error('There was a server error')
    } else if (res.status === 401) {
        throw new Error('Unauthorized - 401')
    } else if (!sessionData?.access_token) {
        throw new Error('A token was not returned by the server')
    } else {
        return sessionData
    }
}

export const refreshAccessToken = async (serverHostname: string) => {
    const sessionData = await refreshAccessTokenFromAPI(serverHostname)
    await setSessionData(sessionData)
}

export const notifyNewSession = async () => {}

export const getSession = async (): Promise<SessionResponse | null> => {
    const session = {
        access_token: await sessionStorage.getItem('access_token'),
        refresh_token: await sessionStorage.getItem('refresh_token'),
        access_token_expires_at: await sessionStorage.getItem(
            'access_token_expires_at'
        ),
        refresh_token_expires_at: await sessionStorage.getItem(
            'refresh_token_expires_at'
        ),
        session_expires_at: await sessionStorage.getItem('session_expires_at'),
    }
    if (!session?.access_token) return null
    return session
}

export const broadcastSessionDataChange = async (
    _broadcastChannel?: BroadcastChannel
): Promise<void> => {
    let channel = _broadcastChannel
    if (doesWindowHaveBroadcastChannel()) {
        channel = new BroadcastChannel(SESSION_SHARING_BROADCAST_CHANNEL)
    }

    if (channel) {
        channel.postMessage({
            type: SessionSharingMessageType.AUTH_TOKEN_RESPONSE,
            session: await getSession(),
        })
        if (!_broadcastChannel) channel?.close()
    }
}

export const broadcastClearSessionData = async (
    _broadcastChannel?: BroadcastChannel
): Promise<void> => {
    let channel = _broadcastChannel
    if (doesWindowHaveBroadcastChannel()) {
        channel = new BroadcastChannel(SESSION_SHARING_BROADCAST_CHANNEL)
    }
    if (channel) {
        channel.postMessage({
            type: SessionSharingMessageType.CLEAR_SESSION_DATA,
        })
        if (!_broadcastChannel) channel?.close()
    }
}
