import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

export function makeApolloWebsocketLink(wsUri: string) {
    return typeof window === 'undefined'
        ? null
        : new GraphQLWsLink(
              createClient({
                  url: wsUri,
                  retryAttempts: 1000,
                  lazy: true,
                  connectionParams: async () => {
                      const token = sessionStorage.getItem('access_token')
                      return {
                          ...(token
                              ? { Authorization: `Bearer ${token}` }
                              : {}),
                      }
                  },
              })
          )
}
