import { useState, useEffect } from 'react'
import { useAtom } from 'jotai'

import { useBroadcastChannel } from './useBroadcastChannel'
import {
    setSessionData,
    SessionResponse,
    getSession,
    SessionSharingMessageType,
    broadcastSessionDataChange,
    SESSION_SHARING_BROADCAST_CHANNEL,
} from '@docpace/shared-react-utils/sessionUtils'
import { ctxIsSessionInitialized } from '@docpace/shared-react-atoms'

interface UseSessionSharingProps {
    channelName?: string
}

export const useSessionSharing = ({
    channelName = SESSION_SHARING_BROADCAST_CHANNEL,
}: UseSessionSharingProps = {}) => {
    const [lastUpdated, setLastUpdated] = useState<Date | null>(null)
    const [ isSessionInitialized, setIsSessionInitialized ] = useAtom(ctxIsSessionInitialized)
    const [sessionSharingChannel] = useBroadcastChannel({
        channelName,
        handleMessage: (data) => {
            return handleMessage(data)
        },
    })

    useEffect(() => {
        if (sessionSharingChannel) {
            sessionSharingChannel.onmessage = ({ data }) => handleMessage(data)
            try {
                if (typeof window !== 'undefined') {
                    window.setTimeout(() => {
                        sessionSharingChannel?.postMessage({
                            type: SessionSharingMessageType.AUTH_TOKEN_REQUEST,
                        })
                    }, 200)
                }
            } catch (e) {
                console.log('Message could not be posted', e)
            }
        }
        if (typeof window !== 'undefined') {
            window.setTimeout(() => {
                setIsSessionInitialized(true)
            }, 1000)
        }
    }, [])

    const notifySessionUpdates = async (): Promise<void> => {
        try {
            const session = await getSession()
            if (session && sessionSharingChannel) {
                await broadcastSessionDataChange(sessionSharingChannel)
            }
        } catch (e) {
            console.error(e)
        }
    }

    const setSessionAndNotify = async (
        sessionData: SessionResponse
    ): Promise<void> => {
        await setSessionData(sessionData)
        await notifySessionUpdates()
        setLastUpdated(new Date())
    }

    const clearSession = async (): Promise<void> => {
        await setSessionData(null)
        setLastUpdated(null)
    }

    const handleMessage = async (data: any) => {
        if (
            sessionSharingChannel &&
            data?.type === SessionSharingMessageType.AUTH_TOKEN_REQUEST
        ) {
            try {
                await broadcastSessionDataChange(sessionSharingChannel)
            } catch (e) {
                console.log('Session sharing message was not posted', e)
            }
        } else if (
            data?.type === SessionSharingMessageType.AUTH_TOKEN_RESPONSE
        ) {
            setSessionData(data?.session)
            setIsSessionInitialized(true)
            setLastUpdated(new Date())
        } else if (
            data?.type === SessionSharingMessageType.CLEAR_SESSION_DATA
        ) {
            setSessionData(null)
            setLastUpdated(null)
        }
    }

    return {
        clearSession,
        setSessionAndNotify,
        isInitialized: isSessionInitialized,
        lastUpdated,
    }
}
