import { atom, useAtom, WritableAtom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import { atomEffect } from 'jotai-effect'
import { ctxPracticeIdAtom } from '.'
import { PracticeOverviewFragment } from '@docpace/shared-graphql/fragments'

export const ctxPracticeName = atom<string>('')
export const ctxPracticeAtom = atomWithReset<PracticeOverviewFragment | undefined>(undefined)
export const ctxIsChangingRoute = atom<boolean>(false)
export const ctxIsSessionInitialized = atom<boolean>(false)
export const ctxIsSidebarOpen = atom<boolean>(false)
export const ctxIsSidebarPinned = atom<boolean>(false)
export const ctxIsLoggingOut = atom<boolean>(false)
export const ctxIsAgreeingToTOS = atom<boolean>(false)
export const ctxAdminOverrideManagerId = atomWithReset<string | undefined>(undefined)
export const ctxSelectedAppointmentId = atomWithReset<string | undefined>(undefined)
export const ctxApiServerHostname = atom<string>('')
export const ctxHeaderSubtitleOverride = atom<string>('')

export const sharedUiAtomsPracticeReset = atomEffect((get, set)=>{
    // reset everything when the practice id changes
    get(ctxPracticeIdAtom)
    set(ctxSelectedAppointmentId, null)
})
